import React from "react"
import Helmet from "react-helmet"
import Layout from "../components/layout/layout"
import Wrapper from "../components/layout/wrapper/wrapper"
import FormattedLink from "../components/formattedLink/formattedLink"

import Icon from "../../assets/svg/icon404.svg"

import styles from "./404.module.scss"

const NotFoundPage = () => (
  <Layout>
    <Helmet title="Oops, we can't find that page!" />
    <Wrapper>
      <h1 className={styles.title}>
        <span className={styles.pink}>Oops,</span> we can't find that page!
      </h1>
      <p className={styles.text}>
        Return to the safety of our{" "}
        <FormattedLink type="link-pink" link="/">
          homepage
        </FormattedLink>{" "}
        or discover more about{" "}
        <FormattedLink type="link-pink" link="/elearning-authoring-tool/">
          Gomo.
        </FormattedLink>
      </p>
    </Wrapper>
    <Wrapper noPadding>
      <Icon className={styles.icon} />
    </Wrapper>
  </Layout>
)

export default NotFoundPage
